// src/components/Hero.js
import React, {useState, useEffect} from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import './Hero.css';

const Hero = () => {
  const videoFileName = 'hero_video.mp4';
  const quotes = [
    '"Erfolg hat drei Buchstaben: TUN" - Johann Wolfang von Goethe',
    '"Beim Laufen finden wir zu der Persönlichkeit, die wir sein wollen." - George Sheehan',
    '"Fällst du sieben Mal, stehe acht Mal auf." - Japanisches Sprichwort',
    '"Das Leben ist zum Mitmachen da, nicht zum Zuschauen." - Katherine Switzer',
    '"Niemand kann dir ohne deine Zustimmung das Gefühl geben, minderwertig zu sein." - Eleanor Roosevelt'
  ];

  const [randomQuote, setRandomQuote] = useState('');

  useEffect(() => {
    // Wähle ein zufälliges Zitat aus der Liste
    const randomIndex = Math.floor(Math.random() * quotes.length);
    setRandomQuote(quotes[randomIndex]);
  }, []); // Leerer Abhängigkeitsarray, um den Effekt nur einmalig auszulösen


  return (
    <div id="hero" className="hero-section">
      <video autoPlay loop muted className="hero-video" src={videoFileName} type="video/mp4">
        Dein Browser unterstützt das Video-Tag nicht.
      </video>
      <div className="hero-content">
        <h1>{randomQuote}</h1>
        <button className="signup-button"><Link activeClass="active" smooth spy to="contact">Jetzt anmelden</Link></button>
        {/* Füge hier weiteren Inhalt hinzu */}
      </div>
    </div>
  );
};

export default Hero;
