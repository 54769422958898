import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import './Contact.css';
import Swal from 'sweetalert2';

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_lricn9e",
        "template_ywqwg7y",
        form.current,
        "QyDej-msTFqX6qkXp"
      )
      .then((result) => {
        console.log(result.text);
        Swal.fire({
          icon:'success',
          title:'Deine Nachricht wurde erfolgreich gesendet 👍'
        })
      }, (error) => {
        console.log(error.text);
        Swal.fire({
          icon:'error',
          title:"Irgendwas ist falsch gelaufen 🙃",
          text:error.text,
        })
      });
      form.current.reset()
  };

  return (
    <div id="contact" className="stylecontact">
      <h2>Jetzt anmelden</h2>
      <form ref={form} onSubmit={sendEmail}>
        <label>Name</label>
        <input type="text" name="user_name" required/>
        <label>Email</label>
        <input type="email" name="user_email" required/>
        <label>Für welchen Kurs interessierst du dich?</label>
        <select name="course" required>
        <option value="Fortgeschritten Kurs">Anfängerkurs</option>
        <option value="Halbmarathon Kurs">Special Kurs</option>
        <option value="Personal Training">Personal Training</option>
        </select>
        <label>Nachricht</label>
        <textarea name="message" required/>
        <input type="submit" value="Senden" />
      </form>
      </div>
  );
};

export default Contact;

