import React from 'react';
import { useSpring, animated, config } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import './halbmarathon.css';
import ProgressBar  from '@ramonak/react-progress-bar';
import halbmarathon from './halbmarathon.jpg'

const coursesData = [
  {
    title: 'Special Kurs!!!',
    description: <p>Jede Treppe, die ich finde, jede Brücke, jeder Turm und alles, was anstrengend ist <strong>im Raum Kirchen, Dauersberg, Freusburg.</strong> <strong>Start ist der 22. Mai von 19 Uhr bis 19:30 Uhr.</strong><br/> 
      Der Kurs wird <strong>8 Wochen</strong> gehen. Voraussetzung ist es 2-3 km am Stück zu laufen (langsam).  
      <br/>
      <br/>
      Kursziel: <strong>Spaß an der Bewegung</strong> 
      <br/>
      <br/>
      Preis: <strong>60 Euro</strong></p>,
    availableSeats: 4,
    totalSeats: 8,
    image: halbmarathon, // Füge hier den Bildpfad ein
  },
];

const Halbmarathoncourse = () => {
    return (
      <div className="courses-section">
        {coursesData.map((course, index) => (
          <div key={index} className="course-box">
            <img src={course.image} alt={course.title} />
            <div className="course-details">
              <h3>{course.title}</h3>
              <p>{course.description}</p>
              <div className="progress-bar-fortgeschritten">
                <ProgressBar
                  completed={50} customLabel={`${course.availableSeats}/${course.totalSeats}`}
                  bgColor="#990000"
                  baseBgColor="#ddd"
                  height="20px"
                  initCompletedOnAnimation={0}
                  animateOnRender
                />
                <div className="seats-info">{`${course.availableSeats}/${course.totalSeats} Plätzen belegt`}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  export default Halbmarathoncourse;