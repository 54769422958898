import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import './AboutMe.css';

const AboutMe = () => {
  const isMobile = window.innerWidth <= 768; // Prüfe, ob die Bildschirmbreite kleiner oder gleich 768px ist (für mobile Geräte)
  const [ref, inView] = useInView({
    triggerOnce: true, // Trigger die Animation nur einmal
  });

  // Starte die Animation nur auf Desktop-Geräten
  const imageSpring = useSpring({
    opacity: isMobile ? 1 : (inView ? 1 : 0),
    transform: isMobile ? 'translateX(0)' : (inView ? 'translateX(0)' : 'translateX(-100%)'),
    config: { duration: 2000 },
  });

  const textSpring = useSpring({
    opacity: isMobile ? 1 : (inView ? 1 : 0),
    transform: isMobile ? 'translateX(0)' : (inView ? 'translateX(0)' : 'translateX(100%)'),
    config: { duration: 2000 },
  });

  return (
    <div id="about" className="about-me-section" ref={ref}>
      <div className="about-me-content">
        <div className="about-me-image">
          {/* Füge hier dein Bild ein */}
          <animated.img src={process.env.PUBLIC_URL + '/about_me_image.jpeg'} alt="About Me" style={imageSpring} className="animated-image"/>
        </div>
        <animated.div className="about-me-text" style={textSpring}>
          <h2>Über mich</h2>
          <p>
          Hallo,
          mein Name ist Melanie Cassens-Braune und ich bin seit meiner Kindheit  mit Sport aufgewachsen. Seit ich 19 bin, laufe ich regelmäßig.
          Mit 26 bin ich meinen ersten Marathon in Köln  gelaufen. 
          Das Laufen begeistert  mich, weil ich das überall kann.
          Ich brauche nur Schuhe und Sportkleidung. Die passt in jeden Koffer rein.<br/>
          <br/>
          Nach der Geburt unserer Söhne und einer damit verbundenen Laufpause, habe ich mit dem Laufen langsam wieder begonnen. Durch die Unterstützung meines Mannes konnte ich vor 10 Jahren (2014) meine Laufkurse für Frauen starten. 
          Mein Ziel ist es nicht, auf Leistung zu trainieren, sondern das Jede sich in ihrem Körper wohl fühlt und zur Ruhe kommt.
          <br/>
          <br/>
          Es gibt zwei Grundsätze:<br/>
          <strong>Auch Regen kann man mögen!</strong><br/>
          Und: <strong>In der Bewegung zur Ruhe kommen.</strong> <br/>
          Jeder Mensch ist anders und ich komme vom Arbeiten zur Ruhe, wenn ich laufe. 
          Da werden Körper und Seele eine Einheit.
          Nun wünsche ich Euch viel Spaß auf meiner Homepage.<br/>
          Hier noch einmal einen großen Dank an <a target="_blank" href="https://www.ocean-site.de">Oguzhan Icli</a>, für die wundervolle Gestaltung meiner Seite.
          <br/>
          <br/>
          - Eure Mel
          </p>
        </animated.div>
      </div>
    </div>
  );
};

export default AboutMe;
