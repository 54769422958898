import React from 'react';
import { useSpring, animated, config } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import './fortgeschritten.css';
import ProgressBar  from '@ramonak/react-progress-bar';
import laufen from './laufen.jpg'

const coursesData = [
  {
    title: 'Anfängerkurs',
    description: <p>Verbessere deine Ausdauer und Laufleistung mit dem Anfänger Laufkurs, der <strong>ab Mittwoch, den 08. Mai um 18 Uhr</strong> stattfindet. 
    <br/>Treffpunkt wird der Ottoturm oder der Radweg in Kirchen-Wehbach sein. Kann nach Absprache wechseln.  
    <br/>
    <br/>
    Kursziele: <strong>30-45 Minuten am Stück laufen</strong> 
    <br/>
    <br/>
    Preis: <strong>65 Euro</strong></p>,
    availableSeats: 4,
    totalSeats: 8,
    image: laufen, // Füge hier den Bildpfad ein
  },
];

const Intermediatecourse = () => {
  const [ref, inView] = useInView({
    
  });

 

  
    return (
      <div className="courses-section" ref={ref}>
        {coursesData.map((course, index) => (
          <div key={index} className="course-box">
            <img src={course.image} alt={course.title} />
            <div className="course-details">
              <h3>{course.title}</h3>
              <p>{course.description}</p>
              <animated.div className="progress-bar-intermed" >
                <ProgressBar
                  completed={50} customLabel={`${course.availableSeats}/${course.totalSeats}`}
                  bgColor="#990000"
                  baseBgColor="#ddd"
                  height="20px"
                  initCompletedOnAnimation={0}
                  animateOnRender
                />
                <div className="seats-info">{`${course.availableSeats}/${course.totalSeats} Plätzen belegt`}</div>
              </animated.div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  export default Intermediatecourse;