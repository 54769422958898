// src/components/Footer.js
import React, {useState} from 'react';
import Impressum from './Impressum';
import Datenschutzerklaerung from './Datenschutzerklaerung';
import './Footer.css'; // Erstellen wir diese Datei gleich
import { TfiInstagram } from 'react-icons/tfi';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  


  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="right-content">
        <Impressum />
      <Datenschutzerklaerung />
        </div>
        <div className="left-content">
        <span className="instagram-link">
      <a href="https://www.instagram.com/melcassens/" target="_blank" rel="noopener noreferrer">
        <TfiInstagram />
      </a>
    </span>
          <p>&copy; {currentYear} Lauf mit Mel</p>
          <p>Made with ❤️ by <a href="https://ocean-site.de" target="_blank" rel="noopener noreferrer">ocean-site</a></p>
        </div>
        
      </div>
    </footer>
  );
};

export default Footer;
