// src/components/ImpressumModal.js
import React, { useState } from 'react';
import Modal from 'react-modal';
import './Modal.css';

Modal.setAppElement('#root'); // für die Barrierefreiheit

const Impressum = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const customStyles = {
    content: {
      width: '30%', // Beispiel: Breite auf 60% des Bildschirms
      margin: 'auto', // Zentriert das Modal horizontal
      zIndex: '1000',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)', // Schatten
      padding: '20px', // Fügt Innenabstand hinzu
      fontSize: '16px',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)', // Hintergrundfarbe des Overlays
        zIndex: 1001, // Beispiel: Z-index für das Overlay setzen
        
      },
  };

  // Media Query für Smartphones
if (window.innerWidth <= 768) {
  customStyles.content.width = '70%'; // Beispiel: Ändere die Breite für Smartphones
  customStyles.content.margin = '0';
  customStyles.content.padding = '0';
  customStyles.content.fontSize = '10px';
}

  return (
    <div>
      <button className="open-button" onClick={openModal}>Impressum</button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Impressum Modal"
        style={customStyles}
      >
        <h1>Impressum</h1>
        <h2>Angaben gemäß 5 TMG</h2>
        <p>Melanie Cassens-Braune<br />Tulpenweg, 7a<br />57518 Betzdorf</p>
        <h2>Kontakt</h2>
        <p>Telefon: 015129803153<br />
        E-Mail: <a href="mailto:mel@laufmitmel.de">mel@laufmitmel.de</a></p>
        <h2>Redaktionell verantwortlich</h2>
        <p><a href="https://ocean-site.de" target="_blank">https://ocean-site.de/</a></p>
        
        <button className="close-button" onClick={closeModal}>Schließen</button>
      </Modal>
    </div>
  );
};

export default Impressum;

