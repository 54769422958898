import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import AboutMe from './components/AboutMe';
import Courses from './components/Courses/Courses';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Impressum from './components/Impressum';
import Datenschutzerklaerung from './components/Datenschutzerklaerung';
import { FaCpanel } from 'react-icons/fa';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <AboutMe />
      <Courses />
      <Contact />
      <Footer />

      {/* Füge hier weitere Abschnitte hinzu, wenn nötig */}
    </div>
  );
}

export default App;
