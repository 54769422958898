import React from 'react';
import { useSpring, animated, config } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import './personaltr.css';
import ProgressBar  from '@ramonak/react-progress-bar';
import personaltr from './personaltr.jpg';

const coursesData = [
  {
    title: 'Personal Training',
    description: <p>Erreiche deine persönlichen Fitnessziele mit deinem maßgeschneiderten Personal Training! 
      Unsere Sitzungen finden <strong>einmal pro Woche</strong> statt und werden individuell nach deinen Bedürfnissen gestaltet.<br/> 
      Gemeinsam mit mir setzt du Ziele und entwickelst einen Trainingsplan, der perfekt auf dich zugeschnitten ist. 
      <br/>
      <br/>
      Kursziele: <strong>Individuell</strong>
      <br/>
      <br/>
      Preis: <strong>65 Euro pro Monat</strong></p>,
    availableSeats: 3,
    totalSeats: 3,
    image: personaltr, // Füge hier den Bildpfad ein
  },
];

const Personalcourse = () => {
    return (
      <div className="courses-section">
        {coursesData.map((course, index) => (
          <div key={index} className="course-box">
            <img src={course.image} alt={course.title} />
            <div className="course-details">
              <h3>{course.title}</h3>
              <p>{course.description}</p>
              <div className="progress-bar-intermed">
                <ProgressBar
                  completed={100} customLabel={`${course.availableSeats}/${course.totalSeats}`}
                  bgColor="#990000"
                  baseBgColor="#ddd"
                  height="20px"
                  initCompletedOnAnimation={0}
                  animateOnRender
                />
                <div className="seats-info">{`${course.availableSeats}/${course.totalSeats} Plätzen belegt`}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  export default Personalcourse;