import React from 'react';
import './Courses.css';
import Intermediatecourse from './Fortgeschritten/fortgeschritten'; 
import Halbmarathoncourse from './Halbmarathon/halbmarathon';
import Personalcourse from './PersonalTraining/personaltr';

const Courses = () => {
  return (
    <div id="courses" className="courses-section">
      <Intermediatecourse />
      <Halbmarathoncourse />
      <Personalcourse />
    </div>
  );
};

export default Courses;
